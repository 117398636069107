/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@font-face {   
  font-family: 'Filxgirl';    //This is what we are going to call
  src: url('assets/fonts/SVN-Fiolex Girls.TTF');
  // src: url('assets/fonts/Filxgirl.TTF');
}
.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.circle-img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
 }
 .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .lines {
    display: flex;
    width: 70%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}

.thumbnail{
  padding:0px;
  margin: 0px;
  padding-right: 1px;
}

.shadow {
  box-shadow: 6px 10px 25px 0 rgb(0 0 0 / 19%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  border-radius: 33px;
}
.container {
margin-right: auto;
margin-left: auto;

width: 100%;
@media (min-width: 1200px)
{
    max-width: 1140px;
}
@media (min-width: 992px)
{
    max-width: 960px;
}
@media (min-width: 768px)
{
    max-width: 720px;
}
@media (min-width: 576px)
{
    max-width: 540px;
}
}

.shadowProfile {
box-shadow: 6px 10px 25px 0 rgb(0 0 0 / 19%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
}
